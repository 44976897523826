var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "dialogType": "warning",
      "max-width": "700px"
    },
    on: {
      "close": function ($event) {
        _vm.youSure = false;
      },
      "click-outside": function ($event) {
        _vm.youSure = false;
      },
      "keydown-esc": function ($event) {
        _vm.youSure = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.promptText) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": "warning"
          },
          on: {
            "click": _vm.actionPerformed
          }
        }, [_vm._v(" " + _vm._s(_vm.actionOption))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.youSure,
      callback: function ($$v) {
        _vm.youSure = $$v;
      },
      expression: "youSure"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };