import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    attrs: {
      "scrollable": "",
      "max-width": "1000"
    },
    on: {
      "close": function ($event) {
        _vm.appointmentSearch = false;
      },
      "click-outside": function ($event) {
        _vm.appointmentSearch = false;
      },
      "keydown-esc": function ($event) {
        _vm.appointmentSearch = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Terminsuche ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VSimpleTable, [_c('tbody', [_c('tr', {
          staticClass: "mt-2"
        }, [_c('td', {
          attrs: {
            "colspan": "3"
          }
        }, [_c(VTooltip, {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c(VTextField, _vm._g({
                staticClass: "pa-3",
                attrs: {
                  "append-icon": "mdi-magnify",
                  "label": "Textsuche",
                  "hide-details": ""
                },
                on: {
                  "keyup": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
                    return _vm.searchAppointments.apply(null, arguments);
                  }
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v;
                  },
                  expression: "searchText"
                }
              }, on))];
            }
          }])
        }, [_c('span', [_vm._v("Suche nach Betreff, Adressen, Objekte und Ressourcen")])])], 1)]), _c(VDataTable, {
          ref: "vuetable",
          staticClass: "elevation-1",
          attrs: {
            "headers": _vm.headers,
            "items": _vm.appointments,
            "items-per-page": 10
          },
          scopedSlots: _vm._u([{
            key: "no-data",
            fn: function () {
              return [_vm._v(" Keine Daten gefunden. ")];
            },
            proxy: true
          }, {
            key: "item.actions",
            fn: function ({
              item
            }) {
              return [_c(VBtn, {
                staticClass: "mr-2 col-xl-5",
                attrs: {
                  "fab": "",
                  "small": "",
                  "text": "",
                  "outlined": "",
                  "elevation": "0"
                },
                on: {
                  "click": function ($event) {
                    return _vm.setToDate(item);
                  }
                }
              }, [_c(VIcon, [_vm._v("mdi-calendar-cursor-outline")])], 1), _c(VBtn, {
                staticClass: "mr-2 col-xl-5",
                attrs: {
                  "fab": "",
                  "small": "",
                  "text": "",
                  "outlined": "",
                  "elevation": "0"
                },
                on: {
                  "click": function ($event) {
                    return _vm.editAppointment(item);
                  }
                }
              }, [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-pencil ")])], 1), _c(VBtn, {
                staticClass: "col-xl-5",
                attrs: {
                  "fab": "",
                  "small": "",
                  "text": "",
                  "elevation": "0",
                  "outlined": "",
                  "color": "red"
                },
                on: {
                  "click": function ($event) {
                    return _vm.tryDeleteAppointment(item);
                  }
                }
              }, [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-delete ")])], 1)];
            }
          }])
        })], 1)])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.appointmentSearch,
      callback: function ($$v) {
        _vm.appointmentSearch = $$v;
      },
      expression: "appointmentSearch"
    }
  }), _c('Loading', {
    ref: "loading"
  }), _c('YouSure', {
    ref: "sureToDelete",
    on: {
      "actionPerformed": _vm.deleteAppointment
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };