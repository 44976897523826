var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Loading', {
    ref: "loading"
  }), _c('EditAppointment', {
    ref: "editAppointment",
    on: {
      "checkDoubleClick": _vm.handleCheckDoubleClick
    }
  }), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  }), _c('NavigationBar', {
    ref: "nav",
    attrs: {
      "id": "calNav",
      "monthYearTitle": _vm.monthYearTitle
    },
    on: {
      "nextPage": _vm.nextPage,
      "prevPage": _vm.prevPage,
      "setToday": _vm.setToday,
      "changeTypeOfView": _vm.changeTypeOfView,
      "navigateToDate": _vm.navigateToDate,
      "addAppointment": _vm.addAppointment,
      "editAppointment": _vm.editAppointment,
      "deleteAppointment": _vm.deleteAppointment,
      "setToDate": _vm.setToDate,
      "applyFilter": _vm.applyFilter,
      "clearFilter": _vm.clearFilter,
      "zoom": _vm.zoom,
      "addAppointmentPlusCheckbox": _vm.addAppointmentPlusCheckbox
    }
  }), _vm.isCalendar ? _c('CalendarOverview', {
    ref: "calendarOverview",
    attrs: {
      "calendarHeight": _vm.calendarHeight
    },
    on: {
      "monthYearTitleChanged": _vm.updateMonthYearTitle,
      "updateRange": _vm.updateRange,
      "editAppointment": _vm.editAppointment,
      "deleteAppointment": _vm.deleteAppointment,
      "setCategoryProp": _vm.setCategoryProp,
      "addAppointment": _vm.addAppointment,
      "updateViewType": _vm.updateViewType,
      "normalAppointmentAllDay": _vm.normalAppointmentAllDay,
      "createDayEventCheckbox": _vm.createDayEventCheckbox
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };