import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    on: {
      "close": function ($event) {
        _vm.moveAppointmentModal = false;
        _vm.undo();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Termin verschieben ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          attrs: {
            "align": "center"
          }
        }, [_c('span', {
          staticClass: "text-h4"
        }, [_vm._v(_vm._s(_vm.appointment.title))])]), _c(VContainer, {
          attrs: {
            "fluid": ""
          }
        }, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "5.5"
          }
        }, [_c(VCard, {
          staticClass: "rounded-0",
          staticStyle: {
            "border": "1px solid #757575"
          },
          attrs: {
            "elevation": "0"
          }
        }, [_c(VCardText, [_c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-start-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(_vm.currentDraggedEvent.start)) + " ")], 1), !_vm.currentDraggedEvent.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-start")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(_vm.currentDraggedEvent.start))) + " ")], 1) : _vm._e()]), _c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-end-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(_vm.currentDraggedEvent.end)) + " ")], 1), !_vm.currentDraggedEvent.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-end")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(_vm.currentDraggedEvent.end))) + " ")], 1) : _vm._e(), _c('p', [_c(VIcon, [_vm._v("mdi-texture-box")]), _vm._v(" " + _vm._s(_vm.currentDraggedEvent.scope.name) + " ")], 1), _c('p', [_c(VIcon, [_vm._v("mdi-map-marker")]), _vm._v(" " + _vm._s(_vm.currentDraggedEvent.location.name) + " ")], 1)])])], 1)], 1), _c(VCol, {
          staticStyle: {
            "display": "flex",
            "justify-content": "center",
            "align-items": "center"
          },
          attrs: {
            "cols": "1"
          }
        }, [_c(VIcon, {
          attrs: {
            "size": "48"
          }
        }, [_vm._v("mdi-chevron-double-right")])], 1), _c(VCol, {
          attrs: {
            "cols": "5.5"
          }
        }, [_c(VCard, {
          staticClass: "rounded-0",
          staticStyle: {
            "border": "1px solid #757575"
          },
          attrs: {
            "elevation": "0"
          }
        }, [_c(VCardText, [_c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-start-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(_vm.appointment.start)) + " ")], 1), !_vm.appointment.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-start")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(_vm.appointment.start))) + " ")], 1) : _vm._e()]), _c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-end-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(_vm.appointment.end)) + " ")], 1), !_vm.appointment.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-end")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(_vm.appointment.end))) + " ")], 1) : _vm._e(), _c('p', [_c(VIcon, [_vm._v("mdi-texture-box")]), _vm._v(" " + _vm._s(_vm.appointment.scope.name) + " ")], 1), _c('p', [_c(VIcon, [_vm._v("mdi-map-marker")]), _vm._v(" " + _vm._s(_vm.appointment.location.name) + " ")], 1)])])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              _vm.moveAppointmentModal = false;
              _vm.save();
            }
          }
        }, [_vm._v(" Änderung übernehmen ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.moveAppointmentModal,
      callback: function ($$v) {
        _vm.moveAppointmentModal = $$v;
      },
      expression: "moveAppointmentModal"
    }
  }), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };