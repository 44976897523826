import TypeOfCalendarOverviewMenu from '@/components/calendar_v2/TypeOfCalendarOverviewMenu.vue';
import AppointmentSearch from '@/components/calendar_v2/AppointmentSearch.vue';
import AppointmentFilter from '@/components/calendar_v2/AppointmentFilter.vue';
import userSettingsService from '@/services/settings/userSettingsService';
export default {
  data: () => ({
    fab: false,
    title: '',
    navigationDatePicker: false,
    navigationDate: null,
    isFilterSet: false,
    zoomValue: '100%',
    resetZoomValue: '100%',
    minZoomValue: '20%',
    maxZoomValue: '200%',
    userSettingsService,
    typeView: ''
  }),
  computed: {
    currentDisplay() {
      return this.$vuetify.breakpoint.name;
    }
  },
  components: {
    TypeOfCalendarOverviewMenu,
    AppointmentSearch,
    AppointmentFilter
  },
  methods: {
    updateViewType() {
      //call child function from "TypeOfCalendarOverviewMenu.vue"
      this.$refs.typeOfCalendarOverviewMenu.changeTypeView('day');
    },
    updateZoomValue(value) {
      this.zoomValue = value;
    },
    openFilter() {
      this.$refs.filter.open();
    },
    clearFilter() {
      this.$refs.filter.clearFilter();
      this.isFilterSet = false;
      this.$emit('clearFilter');
    },
    applyFilter() {
      this.isFilterSet = true;
      this.$emit('applyFilter');
    },
    nextPage() {
      this.$emit('nextPage');
    },
    prevPage() {
      this.$emit('prevPage');
    },
    setToday() {
      this.$emit('setToday');
    },
    setToDate(date) {
      this.$emit('setToDate', date);
    },
    getTypeOfView() {
      return this.$refs.typeOfCalendarOverviewMenu.typeView;
    },
    changeTypeOfView(newView, days) {
      this.typeView = this.getTypeOfView();
      this.$emit('changeTypeOfView', newView, days);
    },
    navigateToDate() {
      this.$emit('navigateToDate', this.navigationDate);
    },
    addAppointment() {
      this.$emit('addAppointment');

      //call addAppointmentPlusCheckbox() in parent Calendar_v2.vue
      this.$emit('addAppointmentPlusCheckbox');
    },
    editAppointment(appointment) {
      this.$emit('editAppointment', appointment);
    },
    deleteAppointment(appointment) {
      this.$emit('deleteAppointment', appointment);
    },
    zoom(plus, reset = false) {
      this.$emit('zoom', plus, reset);
    },
    setInitialZoom() {
      if (userSettingsService.getValueFromName('zoom') !== null) {
        this.zoomValue = Math.round(userSettingsService.getValueFromName('zoom') / 50 * 100) + '%';
        this.resetZoomValue = Math.round(userSettingsService.getValueFromName('zoom') / 50 * 100) + '%';
      } else {
        this.zoomValue = '100%';
      }
    }
  },
  mounted() {
    this.typeView = this.getTypeOfView();
    this.setInitialZoom();
  },
  props: ['monthYearTitle']
};