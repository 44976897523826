import dateTimeService from '@/services/cal/dateTimeService.js';
import userSettingsService from '../../services/settings/userSettingsService';
import contextMenuService from '../../services/settings/contextMenuService';
import Loading from '@/components/calendar_v2/Loading.vue';
import WarningDialog from '@/components/core/WarningDialog.vue';
import colorService from '@/services/util/colorService.js';
import { mapState } from 'vuex';
export default {
  data: () => ({
    selectedOpen: false,
    selectedAppointment: [],
    dateTimeService,
    setting: [],
    contextMenuService
  }),
  computed: {
    ...mapState({
      appointment: state => state.calAppointments.appointment,
      appointmentHistory: state => state.calAppointments.appointmentHistory,
      lockedAppointments: state => state.calAppointments.lockedAppointments
    })
  },
  components: {
    Loading,
    WarningDialog
  },
  methods: {
    open(nativeEvent, appointment) {
      const open = () => {
        console.log(appointment);
        this.selectedAppointment = appointment;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
      this.setting = userSettingsService.getValueFromName('contextMenu').split(';').filter(item => item != '');
      console.warn(this.setting);
    },
    editAppointment() {
      this.$emit('editAppointment', this.selectedAppointment);
    },
    tryDeleteAppointment() {
      this.$emit('tryDeleteAppointment', this.selectedAppointment);
    },
    deleteAppointment() {
      this.$store.dispatch('loadAppointment', this.selectedAppointment.key).then(() => {
        if (this.appointment.serialNumber != 0) {
          var actions = [{
            action: 'single',
            text: 'Einzeltermin löschen',
            icon: 'mdi-calendar'
          }, {
            action: 'serial',
            text: 'Serientermin löschen',
            icon: 'mdi-calendar-multiple'
          }];
          this.$refs.warning.open('Dieser Termin ist Teil einer Terminserie', actions);
        } else {
          this.$emit('deleteAppointment', this.selectedAppointment);
        }
      });
    },
    warningActionPerformed(action) {
      var isSerial = action === 'serial' ? true : false;
      this.$emit('deleteAppointment', this.selectedAppointment, isSerial);
    },
    quickOpenPictureUpload() {
      this.$emit('quickOpenPictureUpload', this.selectedAppointment);
    },
    openAppointmentHistory() {
      this.$emit('openAppointmentHistory', this.selectedAppointment);
    },
    getEventTextColor(event) {
      return colorService.getTextColorForBackground(event.scope.color);
    }
  } /*
    watch: {
    selectedOpen: function() {
      var timeout = 5
      if (
        userSettingsService.getValueFromName('contextMenuDuration') !== null
      ) {
        timeout = userSettingsService.getValueFromName('contextMenuDuration')
      }
      if (this.selectedOpen == true) {
        setTimeout(() => {
          this.selectedOpen = false
        }, timeout * 1000)
      }
    }
    }*/
};