import "core-js/modules/es.array.push.js";
import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  props: {
    items: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    headers: [{
      text: 'Nr.',
      value: 'lfdnr'
    }, {
      text: 'Betreff',
      value: 'title'
    }, {
      text: 'Status',
      value: 'status'
    }, {
      text: 'Fälligkeitsdatum',
      value: 'dueDate'
    }, {
      text: 'Bearbeiter',
      value: 'assignees'
    }, {
      text: 'Adressen',
      value: 'addresses'
    }],
    search: '',
    dateTimeService
  }),
  methods: {
    addTask() {
      this.$router.push('/task/new');
    },
    openDetailView(value) {
      this.$router.push({
        name: 'TaskDetails',
        params: {
          lfdnr: value.lfdnr
        }
      });
    },
    dueDateIcon(task) {
      if (task.dueDate == null) return 'mdi-calendar-blank';
      if (dateTimeService.isBeforeToday(task.dueDate)) return 'mdi-calendar-today';
      return 'mdi-calendar';
    },
    dueDateIconColor(task) {
      if (task.status.done) return '';else if (dateTimeService.isBeforeToday(task.dueDate)) return 'error';
      return 'primary';
    }
  }
};