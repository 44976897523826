import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import axios from 'axios';
export default {
  data: function () {
    return {
      series: [],
      show: false,
      kdSortname: '',
      current: 'Artikeltypen',
      items: ['Artikeltypen', 'Artikelkategorien', 'Artikelgruppen']
    };
  },
  props: {},
  computed: {
    options: function () {
      return {
        dataLabels: {
          enabled: false
        },
        noData: {
          text: 'Daten werden geladen...',
          align: 'center',
          verticalAlign: 'middle'
        },
        title: {
          text: 'Umsatz/DB - ' + this.kdSortname + ': ' + this.current
        },
        xaxis: {
          type: 'category'
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              });
              return form.format(value);
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          },
          events: {
            dataPointSelection: (e, chart, config) => {
              var grouped = config.w.globals.seriesX[config.seriesIndex][config.dataPointIndex];
              if (grouped !== undefined) {
                axios.get('v1/statistics/keyfigures/distributor/articles', {
                  params: {
                    kdSortname: this.kdSortname,
                    option: this.current,
                    grouped: grouped
                  }
                }).then(data => {
                  this.$emit('openDistributorArticles', data.data, this.kdSortname, grouped);
                });
              }
            }
          }
        }
      };
    }
  },
  mounted() {},
  methods: {
    open(data, kdSortname) {
      this.kdSortname = kdSortname;
      this.current = 'Artikeltypen';
      var seriesData = [];
      var years = [...new Set(data.map(item => item.year))];
      var grouped = [...new Set(data.map(item => item.grouped))];
      years.forEach(year => {
        var yearData = data.filter(item => item.year === year);
        var series = {
          name: year,
          data: []
        };
        grouped.forEach(grouped => {
          var groupedData = yearData.find(item => item.grouped === grouped);
          if (groupedData) {
            series.data.push({
              x: groupedData.grouped,
              y: groupedData.revenue
            });
          } else {
            series.data.push({
              x: grouped,
              y: 0
            });
          }
        });
        seriesData.push(series);
      });
      this.series = seriesData;
      this.show = true;
    },
    loadData() {
      axios.get('v1/statistics/keyfigures/distributor/grouped', {
        params: {
          kdSortname: this.kdSortname,
          option: this.current
        }
      }).then(data => {
        var seriesData = [];
        var years = [...new Set(data.data.map(item => item.year))];
        var grouped = [...new Set(data.data.map(item => item.grouped))];
        years.forEach(year => {
          var yearData = data.data.filter(item => item.year === year);
          var series = {
            name: year,
            data: []
          };
          grouped.forEach(grouped => {
            var groupedData = yearData.find(item => item.grouped === grouped);
            if (groupedData) {
              series.data.push({
                x: groupedData.grouped,
                y: groupedData.revenue
              });
            } else {
              series.data.push({
                x: grouped,
                y: 0
              });
            }
          });
          seriesData.push(series);
        });
        this.series = seriesData;
      });
    }
  }
};