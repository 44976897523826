import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VMenu, {
    attrs: {
      "close-on-content-click": false,
      "activator": _vm.selectedElement,
      "offset-x": ""
    },
    model: {
      value: _vm.selectedOpen,
      callback: function ($$v) {
        _vm.selectedOpen = $$v;
      },
      expression: "selectedOpen"
    }
  }, [_c(VCard, {
    attrs: {
      "color": "grey lighten-4",
      "min-width": "350px",
      "flat": ""
    }
  }, [_c(VToolbar, {
    staticClass: "border-bottom-primary-4",
    attrs: {
      "flat": ""
    }
  }, [_c(VAvatar, {
    staticClass: "mr-4",
    attrs: {
      "color": _vm.selectedAppointment.scope.color,
      "size": "26"
    }
  }), _c(VToolbarTitle, {
    domProps: {
      "innerHTML": _vm._s(_vm.selectedAppointment.title)
    }
  }), _c(VSpacer), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon"
    },
    on: {
      "click": _vm.openAppointmentHistory
    }
  }, [_c(VIcon, [_vm._v("mdi-history")])], 1), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon"
    },
    on: {
      "click": _vm.quickOpenPictureUpload
    }
  }, [_c(VIcon, [_vm._v("mdi-upload")])], 1), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon"
    },
    on: {
      "click": _vm.editAppointment
    }
  }, [_c(VIcon, [_vm._v("mdi-pencil")])], 1), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonType": "error"
    },
    on: {
      "click": _vm.tryDeleteAppointment
    }
  }, [_c(VIcon, [_vm._v("mdi-delete")])], 1)], 1), _c(VCardText, [_c('table', _vm._l(_vm.setting, function (s) {
    return _c('tr', {
      key: s
    }, [s !== 'tel' && s !== 'address' ? _c('td', [_vm._v(" " + _vm._s(_vm.contextMenuService.toGermanName(s)) + ": ")]) : s === 'tel' && _vm.selectedAppointment.telNr !== null ? _c('td', [_vm._v(" " + _vm._s(_vm.contextMenuService.toGermanName(s)) + " ")]) : s === 'address' && _vm.selectedAppointment.firstAddressKdSortname !== null ? _c('td', [_vm._v(" " + _vm._s(_vm.contextMenuService.toGermanName(s)) + " ")]) : _vm._e(), _c('td', {
      staticClass: "pl-5"
    }, [s === 'title' ? [_vm._v(_vm._s(_vm.selectedAppointment.title))] : _vm._e(), s === 'from' ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.allDay ? _vm.dateTimeService.getGermanDateFormatWithoutTime(_vm.selectedAppointment.start) : _vm.dateTimeService.getGermanDateFormat(_vm.selectedAppointment.start)) + " ")] : _vm._e(), s === 'to' ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.allDay ? _vm.dateTimeService.getGermanDateFormatWithoutTime(_vm.selectedAppointment.end) : _vm.dateTimeService.getGermanDateFormat(_vm.selectedAppointment.end)) + " ")] : _vm._e(), s === 'scope' ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.scope.name) + " ")] : _vm._e(), s === 'location' ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.location.name) + " ")] : _vm._e(), s === 'type' ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.type.name) + " ")] : _vm._e(), s === 'state' ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.state.description) + " ")] : _vm._e(), s === 'address' && _vm.selectedAppointment.firstAddressKdSortname != null ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.firstAddressKdSortname) + " ")] : _vm._e(), s === 'tel' && _vm.selectedAppointment.telNr !== null ? [_vm._v(" " + _vm._s(_vm.selectedAppointment.telNr) + " ")] : _vm._e()], 2)]);
  }), 0)]), _c(VCardActions, [_c('LBAButton', {
    attrs: {
      "buttonType": "error",
      "buttonStyle": "text"
    },
    on: {
      "click": function ($event) {
        _vm.selectedOpen = false;
      }
    }
  }, [_vm._v(" Abbrechen ")])], 1)], 1), _c('Loading', {
    ref: "loading"
  }), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };