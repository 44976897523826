import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    attrs: {
      "max-width": "600"
    },
    on: {
      "close": function ($event) {
        _vm.quickUpload = false;
      },
      "click-outside": function ($event) {
        _vm.quickUpload = false;
      },
      "keydown-esc": function ($event) {
        _vm.quickUpload = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Bilderupload - Adressenwahl ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm.appointment.addresses == null || _vm.appointment.addresses.length == 0 ? _c('p', [_vm._v(" Dem Termin sind keine Adressen zugeordnet. ")]) : _c('p', [_vm._v(" Bitte wählen Sie eine Adresse zur Zuordnung: ")]), _c(VSelect, {
          attrs: {
            "items": _vm.appointment.addresses,
            "disabled": _vm.appointment.addresses == null || _vm.appointment.addresses.length == 0,
            "item-text": "name",
            "item-value": "name",
            "return-object": ""
          },
          model: {
            value: _vm.selectedAddress,
            callback: function ($$v) {
              _vm.selectedAddress = $$v;
            },
            expression: "selectedAddress"
          }
        })];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "disabled": _vm.selectedAddress == null
          },
          on: {
            "click": function ($event) {
              _vm.showAddressPicturesDialog = true;
            }
          }
        }, [_vm._v("OK")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.quickUpload,
      callback: function ($$v) {
        _vm.quickUpload = $$v;
      },
      expression: "quickUpload"
    }
  }), _vm.showAddressPicturesDialog ? _c('AddressPicturesDialog', {
    attrs: {
      "address": _vm.selectedAddress,
      "opened": true
    },
    on: {
      "close-dialog": function ($event) {
        return _vm.closeAddressPicturesDialog();
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };