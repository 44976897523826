import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VSheet, {
    attrs: {
      "min-height": "64",
      "max-width": "100%"
    }
  }, [_c('div', {
    staticClass: "py-3"
  }, [_c(VRow, {
    attrs: {
      "align": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center justify-xs-center justify-sm-center justify-md-center justify-lg-center justify-xl-center align-center"
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonSize": "large"
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left")])], 1), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonSize": "large"
    },
    on: {
      "click": _vm.setToday
    }
  }, [_c(VIcon, [_vm._v("mdi-calendar-today")])], 1), _c('h2', {
    staticClass: "mx-sm-5 text-center"
  }, [_vm._v(" " + _vm._s(this.monthYearTitle) + " ")]), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonSize": "large"
    },
    on: {
      "click": function ($event) {
        _vm.navigationDatePicker = true;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-calendar-cursor")])], 1), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonSize": "large"
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right")])], 1)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center justify-xs-center justify-sm-center justify-md-center justify-lg-center justify-xl-center align-center"
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonSize": "large",
      "disabled": _vm.zoomValue === _vm.minZoomValue
    },
    on: {
      "click": function ($event) {
        return _vm.zoom(false);
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-magnify-minus-outline")])], 1), _vm.currentDisplay !== 'xs' && _vm.currentDisplay !== 'sm' && _vm.currentDisplay !== 'md' ? _c('LBAButton', {
    staticClass: "mx-2 mx-xs-2 mx-sm-5",
    attrs: {
      "buttonType": _vm.zoomValue === _vm.resetZoomValue ? 'default' : 'grey',
      "buttonStyle": _vm.zoomValue === _vm.resetZoomValue ? 'text' : 'default'
    },
    on: {
      "click": function ($event) {
        return _vm.zoom(false, true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.zoomValue) + " ")]) : _vm._e(), _vm.currentDisplay === 'xs' || _vm.currentDisplay === 'sm' || _vm.currentDisplay === 'md' ? _c('div', {
    staticClass: "mx-2 mx-xs-2 mx-sm-10"
  }, [_c('TypeOfCalendarOverviewMenu', {
    ref: "typeOfCalendarOverviewMenu",
    on: {
      "changeTypeOfView": _vm.changeTypeOfView
    }
  })], 1) : _vm._e(), _c('LBAButton', {
    attrs: {
      "buttonStyle": "icon",
      "buttonSize": "large",
      "disabled": _vm.zoomValue === _vm.maxZoomValue
    },
    on: {
      "click": function ($event) {
        return _vm.zoom(true);
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-magnify-plus-outline")])], 1)], 1)]), _vm.currentDisplay !== 'xs' && _vm.currentDisplay !== 'sm' && _vm.currentDisplay !== 'md' ? _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center justify-xs-center justify-sm-center justify-md-start justify-lg-center justify-xl-center align-center"
  }, [_c('div', {
    staticClass: "mx-3"
  }, [_c('LBAButton', {
    on: {
      "click": _vm.addAppointment
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")])], 1)], 1), _c('div', {
    staticClass: "mx-3"
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": "outlined"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.appointmentSearch.open();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-magnify")])], 1)], 1), !_vm.isFilterSet ? _c('div', {
    staticClass: "mx-3"
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": "outlined"
    },
    on: {
      "click": _vm.openFilter
    }
  }, [_c(VIcon, [_vm._v("mdi-filter")])], 1)], 1) : _vm.isFilterSet ? _c('div', {
    staticClass: "mx-3"
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": "outlined"
    },
    on: {
      "click": _vm.openFilter
    }
  }, [_c(VIcon, [_vm._v("mdi-filter-cog")])], 1)], 1) : _vm._e(), _vm.isFilterSet ? _c('div', {
    staticClass: "mx-3"
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": "outlined"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_c(VIcon, [_vm._v("mdi-filter-off")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "mx-3"
  }, [_c('TypeOfCalendarOverviewMenu', {
    ref: "typeOfCalendarOverviewMenu",
    on: {
      "changeTypeOfView": _vm.changeTypeOfView
    }
  })], 1)])]) : _vm._e()], 1)], 1), _vm.currentDisplay === 'xs' || _vm.currentDisplay == 'sm' || _vm.currentDisplay === 'md' ? _c('div', [_c(VSpeedDial, {
    attrs: {
      "bottom": "",
      "absolute": "",
      "fixed": "",
      "right": "",
      "direction": "top",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c(VBtn, {
          staticClass: "elevation-0",
          attrs: {
            "large": "",
            "color": "primary",
            "fab": ""
          },
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v;
            },
            expression: "fab"
          }
        }, [_vm.fab ? _c(VIcon, [_vm._v(" mdi-close ")]) : _c(VIcon, [_vm._v(" mdi-menu ")])], 1)];
      },
      proxy: true
    }], null, false, 1943681199),
    model: {
      value: _vm.fab,
      callback: function ($$v) {
        _vm.fab = $$v;
      },
      expression: "fab"
    }
  }, [_c(VBtn, {
    attrs: {
      "fab": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addAppointment
    }
  }, [_c(VIcon, [_vm._v("mdi-plus")])], 1), _c(VBtn, {
    attrs: {
      "fab": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.appointmentSearch.open();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-magnify")])], 1), _c(VBtn, {
    attrs: {
      "fab": "",
      "color": "primary"
    },
    on: {
      "click": _vm.openFilter
    }
  }, [_c(VIcon, [_vm._v(_vm._s(_vm.isFilterSet ? 'mdi-filter-cog' : 'mdi-filter'))])], 1), _vm.isFilterSet ? _c(VBtn, {
    attrs: {
      "fab": "",
      "color": "primary"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_c(VIcon, [_vm._v("mdi-filter-off")])], 1) : _vm._e()], 1)], 1) : _vm._e()]), _c('LBADialog', {
    attrs: {
      "max-width": "350px"
    },
    on: {
      "close": function ($event) {
        _vm.navigationDatePicker = false;
      },
      "click-outside": function ($event) {
        _vm.navigationDatePicker = false;
      },
      "keydown-esc": function ($event) {
        _vm.navigationDatePicker = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Datum wählen ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VDatePicker, {
          attrs: {
            "no-title": "",
            "scrollable": "",
            "first-day-of-week": "1",
            "color": "primary"
          },
          model: {
            value: _vm.navigationDate,
            callback: function ($$v) {
              _vm.navigationDate = $$v;
            },
            expression: "navigationDate"
          }
        })];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              _vm.navigateToDate(), _vm.navigationDatePicker = false;
            }
          }
        }, [_vm._v("Ok")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.navigationDatePicker,
      callback: function ($$v) {
        _vm.navigationDatePicker = $$v;
      },
      expression: "navigationDatePicker"
    }
  }), _c('AppointmentSearch', {
    ref: "appointmentSearch",
    on: {
      "editAppointment": _vm.editAppointment,
      "deleteAppointment": _vm.deleteAppointment,
      "setToDate": _vm.setToDate
    }
  }), _c('AppointmentFilter', {
    ref: "filter",
    on: {
      "applyFilter": _vm.applyFilter
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };