import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "scrollable": "",
      "max-width": "1000px",
      "persistent": "",
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        _vm.appointmentHistory = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Terminverlauf ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VTimeline, _vm._l(_vm.history, function (entry) {
          return _c(VTimelineItem, {
            key: entry.appointment.mdat
          }, [_c(VCard, {
            staticStyle: {
              "border": "1px #e0e0e0 solid"
            },
            attrs: {
              "elevation": "0"
            }
          }, [_c(VCardTitle, [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(entry.appointment.mdat)))]), _c(VCardSubtitle, [_vm._v(_vm._s(entry.appointment.muser))]), _vm._l(entry.changes, function (change) {
            return _c(VCardText, {
              key: change
            }, [_c('b', [_vm._v(_vm._s(_vm.loadGermanPropertyName(Object.keys(change)[0])) + ":")]), _c('br'), _vm._v(" " + _vm._s(_vm.loadValue(change, 0)) + " "), _c(VIcon, [_vm._v("mdi-chevron-double-right")]), _vm._v(" " + _vm._s(_vm.loadValue(change, 1)) + " ")], 1);
          })], 2)], 1);
        }), 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              _vm.appointmentHistory = false;
            }
          }
        }, [_vm._v("OK")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.appointmentHistory,
      callback: function ($$v) {
        _vm.appointmentHistory = $$v;
      },
      expression: "appointmentHistory"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };