import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VMenu, {
    staticClass: "mr-3",
    attrs: {
      "bottom": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c('LBAButton', _vm._g(_vm._b({
          attrs: {
            "buttonStyle": "outlined"
          }
        }, 'LBAButton', attrs, false), on), [_c('span', [_vm._v(_vm._s(_vm.typeView))]), _c(VIcon, {
          attrs: {
            "right": ""
          }
        }, [_vm._v("mdi-menu-down")])], 1)];
      }
    }]),
    model: {
      value: _vm.typeMenu,
      callback: function ($$v) {
        _vm.typeMenu = $$v;
      },
      expression: "typeMenu"
    }
  }, [_c(VList, [_c(VListItem, {
    on: {
      "click": function ($event) {
        return _vm.changeTypeView('day');
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Tag")])], 1), _c(VMenu, {
    attrs: {
      "offset-x": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VListItem, _vm._g(_vm._b({
          staticClass: "white"
        }, 'v-list-item', attrs, false), on), [_c(VListItemTitle, [_vm._v("Woche")])], 1)];
      }
    }])
  }, [_c(VList, [_c(VListItem, {
    on: {
      "click": function ($event) {
        return _vm.changeTypeView('week', 5);
      }
    }
  }, [_c(VListItemTitle, [_vm._v("5-Tage")])], 1), _c(VListItem, {
    on: {
      "click": function ($event) {
        return _vm.changeTypeView('week', 7);
      }
    }
  }, [_c(VListItemTitle, [_vm._v("7-Tage")])], 1)], 1)], 1), _c(VListItem, {
    on: {
      "click": function ($event) {
        return _vm.changeTypeView('month');
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Monat")])], 1)], 1), _c(VMenu, {
    attrs: {
      "offset-x": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VListItem, _vm._g(_vm._b({
          staticClass: "white"
        }, 'v-list-item', attrs, false), on), [_c(VListItemTitle, [_vm._v("Geteilte Ansicht")])], 1)];
      }
    }])
  }, [_c(VList, [_c(VListItem, {
    on: {
      "click": function ($event) {
        return _vm.changeTypeView('scope');
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Bereich")])], 1), _c(VListItem, {
    on: {
      "click": function ($event) {
        return _vm.changeTypeView('location');
      }
    }
  }, [_c(VListItemTitle, [_vm._v("Lokation")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };