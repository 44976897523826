import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mt-4"
  }, [_c('h2', {
    staticClass: "ms-4"
  }, [_vm._v("Aufgaben")]), _c(VSwitch, {
    staticClass: "mx-4",
    attrs: {
      "inset": "",
      "label": _vm.adminViewLabel,
      "disabled": !_vm.isAdmin
    },
    model: {
      value: _vm.adminView,
      callback: function ($$v) {
        _vm.adminView = $$v;
      },
      expression: "adminView"
    }
  }), _c(VTabs, {
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "open"
  }, [_vm._v("Offen")]), _c(VTab, {
    key: "done"
  }, [_vm._v("Abgeschlossen")]), _c(VTab, {
    key: "all"
  }, [_vm._v("Alle")])], 1), _c(VTabsItems, {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    key: "open",
    staticClass: "mt-6 mx-4 mb-4",
    attrs: {
      "transition": "no-transition",
      "reverse-transition": "no-transition"
    }
  }, [_vm.adminView === true ? _c('TaskTable', {
    attrs: {
      "items": _vm.taskPreviews_open
    }
  }) : _vm._e(), _vm.adminView === false ? _c('TaskTable', {
    attrs: {
      "items": _vm.taskPreviewsUser_open
    }
  }) : _vm._e()], 1), _c(VTabItem, {
    key: "all",
    staticClass: "mt-6 mx-4 mb-4",
    attrs: {
      "transition": "no-transition",
      "reverse-transition": "no-transition"
    }
  }, [_vm.adminView === true ? _c('TaskTable', {
    attrs: {
      "items": _vm.taskPreviews_done
    }
  }) : _vm._e(), _vm.adminView === false ? _c('TaskTable', {
    attrs: {
      "items": _vm.taskPreviewsUser_done
    }
  }) : _vm._e()], 1), _c(VTabItem, {
    key: "done",
    staticClass: "mt-6 mx-4 mb-4",
    attrs: {
      "transition": "no-transition",
      "reverse-transition": "no-transition"
    }
  }, [_vm.adminView === true ? _c('TaskTable', {
    attrs: {
      "items": _vm.taskPreviews
    }
  }) : _vm._e(), _vm.adminView === false ? _c('TaskTable', {
    attrs: {
      "items": _vm.taskPreviewsUser
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };