import userSettingsService from '@/services/settings/userSettingsService';
export default {
  data: () => ({
    typeView: '7-Tage',
    typeMenu: false
  }),
  methods: {
    changeTypeView(newView, days = 0) {
      switch (newView) {
        case 'week':
          switch (days) {
            case 5:
              this.typeView = '5-Tage';
              break;
            case 7:
              this.typeView = '7-Tage';
              break;
            default:
              break;
          }
          break;
        case 'day':
          this.typeView = 'Tag';
          break;
        case 'month':
          this.typeView = 'Monat';
          break;
        case 'scope':
          this.typeView = 'Bereich';
          break;
        case 'location':
          this.typeView = 'Lokation';
          break;
        default:
          break;
      }
      this.typeMenu = false;
      this.$emit('changeTypeOfView', newView, days);
    }
  },
  mounted() {
    var type = userSettingsService.getValueFromName('typeOfCalendarOverview');
    if (type !== null) {
      if (type === '5week') {
        this.changeTypeView('week', 5);
      } else if (type === '7week') {
        this.changeTypeView('week', 7);
      } else {
        this.changeTypeView(type);
      }
    } else {
      this.changeTypeView('week', 7);
    }
  }
};