import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "scrollable": "",
      "max-width": "1000px",
      "persistent": "",
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        _vm.appointmentFilter = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Termine filtern ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VContainer, {
          staticClass: "mt-4 mb-4",
          attrs: {
            "justify": "center"
          }
        }, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c(VSelect, {
          staticClass: "mx-sm-1",
          attrs: {
            "prepend-inner-icon": "mdi-calendar-check",
            "outlined": "",
            "label": "Status",
            "items": _vm.states,
            "item-text": "description",
            "item-value": item => item,
            "chips": "",
            "small-chips": "",
            "deletable-chips": "",
            "multiple": ""
          },
          model: {
            value: _vm.filter.states,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "states", $$v);
            },
            expression: "filter.states"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c(VSelect, {
          staticClass: "mx-sm-1",
          attrs: {
            "prepend-inner-icon": "mdi-calendar-weekend-outline",
            "outlined": "",
            "label": "Bereich",
            "items": _vm.scopes,
            "item-text": "name",
            "item-value": item => item,
            "chips": "",
            "small-chips": "",
            "deletable-chips": "",
            "multiple": ""
          },
          model: {
            value: _vm.filter.scopes,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "scopes", $$v);
            },
            expression: "filter.scopes"
          }
        })], 1)], 1), _c(VRow, [_c(VCol, {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c(VSelect, {
          staticClass: "mx-sm-1",
          attrs: {
            "prepend-inner-icon": "mdi-calendar-star-four-points",
            "outlined": "",
            "label": "Terminart",
            "items": _vm.types,
            "item-text": "name",
            "item-value": item => item,
            "chips": "",
            "small-chips": "",
            "deletable-chips": "",
            "multiple": ""
          },
          model: {
            value: _vm.filter.types,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "types", $$v);
            },
            expression: "filter.types"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c(VSelect, {
          staticClass: "mx-sm-1",
          attrs: {
            "prepend-inner-icon": "mdi-map-marker",
            "outlined": "",
            "label": "Lokation",
            "items": _vm.locations,
            "item-text": "name",
            "item-value": item => item,
            "chips": "",
            "small-chips": "",
            "deletable-chips": "",
            "multiple": ""
          },
          model: {
            value: _vm.filter.locations,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "locations", $$v);
            },
            expression: "filter.locations"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              return _vm.applyFilter();
            }
          }
        }, [_vm._v(" Anwenden ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.appointmentFilter,
      callback: function ($$v) {
        _vm.appointmentFilter = $$v;
      },
      expression: "appointmentFilter"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };